body,
html {
  margin: 0;
  font-family: "DM Sans", sans-serif !important;
  color: #23272a;
}

* {
  font-family: "DM Sans", sans-serif !important;
}

.StickyHeader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* color: #23272A; */
  /* background-color: #fff; */
  text-align: center;
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 12%) 0px -1px 12px;
  border-bottom: 1px solid grey;
}

.StickyFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #949db2;
  background-color: #303743;
  text-align: center;
  padding: 0px 20px;
}

.StickyContent {
  position: absolute;
  top: 99px;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 55px;
  /* background-color: #fff; */
  /* color: #fff; */
  overflow: auto;
  padding: 20px;
  bottom: 70px;
  max-width: 1200px;
  margin: auto;
}

.StickyContentQA {
  position: absolute;
  top: 10%;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 55px;
  /* background-color: #fff; */
  /* color: #fff; */
  overflow: auto;
  padding: 12px 20px 12px 20px;
  bottom: 70px;
  /* max-width: 1750px; */
  margin: auto;
}

.stepPractise .MuiStepper-horizontal {
  padding: 15px 15px 15px 15px;
  border-bottom: 2px solid #bfbfbf;
  overflow: auto;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #39aed9 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #39aed9 !important;
}

.primaryBtn {
  text-transform: capitalize !important;
  font-size: 14px !important;
  border: none !important;
  background-color: #39aed9 !important;
}

.outlinedBtn {
  text-transform: capitalize !important;
  font-size: 14px !important;
  border: 1px solid #39aed9 !important;
}

.secondaryBtn {
  text-transform: capitalize !important;
  font-size: 14px !important;
  border: none !important;
  background-color: #f96d64 !important;
}

.tranparentBtn {
  text-transform: capitalize !important;
  font-size: 14px !important;
  border: 1px solid #fff !important;
  color: #fff !important;
}

.practiceModal .MuiDialog-paper {
  max-width: 800px !important;
  width: 900px;
  margin: 0px !important;
  padding: 0px !important;
}

.primaryBtn:disabled {
  opacity: 0.7 !important;
  color: #fff !important;
}

.StickyContent::-webkit-scrollbar {
  display: none;
}

.footerParent {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.footerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
}

.lowerTextLeft {
  font-size: 12px;
  /* color: grey; */
}

@media only screen and (max-width: 768px) {
  .footerParent {
    display: inline-block;
  }

  .videoCard {
    margin-top: 85px !important;
  }

  .footerBtn {
    position: unset;
  }
}

.timer {
  /* position: absolute; */
  top: 0px;
  right: 150px;
}

@media only screen and (max-width: 992px) {
  .videoCard {
    margin-top: 85px !important;
  }

  .timer {
    transform: translateX(-50%);
    left: 50%;
    width: 195px;
  }
}

@media only screen and (min-width: 992px) {
  .bottomText {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
  }
}

textarea:focus-visible {
  border: 1px solid #ced4da !important;
}
